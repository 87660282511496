<!-- 设备维护详情 -->
<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <ykc-detail-plus class="box-card" :labelWidth="120">
      <div slot="header" class="clearfix-title">
        <span>设施设备定期检查维护记录表详情</span>
      </div>
      <ykc-detail-item-plus label="电站名称">
        <span>{{ dealData(details.stationName) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="检查时间">
        <span>{{ dealData(details.checkDate) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="维护保养时间">
        <span>{{ dealData(details.maintenanceDate) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="维护保养记录">
        <span>{{ dealData(details.maintenanceRecord) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="检查维护保养人">
        <span>{{ dealData(details.maintenancePerson) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="备注">
        <span>{{ dealData(details.remark) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="附件">
        <viewer :images="details.filePath">
          <img
            v-for="src in details.filePath"
            :src="src"
            :key="src.title"
            alt=""
            width="80"
            height="80" />
        </viewer>
      </ykc-detail-item-plus>
    </ykc-detail-plus>
  </scroll-layout>
</template>

<script>
  import { omManagementApi } from '@/service/apis';
  import { dealData } from '@/utils/index';

  export default {
    name: 'equipmentMaintenanceDetail',
    data() {
      return {
        id: null,
        details: {},
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    created() {
      this.id = Number(this.$route.query.id);
      this.getDetail();
    },
    methods: {
      dealData,
      /**
       * 获取详情数据
       */
      getDetail() {
        omManagementApi
          .equipmentMaintenanceDetail({ id: this.id })
          .then(res => {
            if (res !== null) {
              this.details = res;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .detail-item span {
    word-break: break-all;
  }
  .box-card {
    .clearfix-title {
      padding: 10px;
      position: relative;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
      &::after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #e2e0e0;
      }
    }
    ::v-deep .el-card__body {
      margin: 30px 15px 0;
    }
    .detail-item img {
      margin-right: 20px;
    }
  }
</style>
